//JQuery
import $ from "jquery"
//Bootstrap
//import 'font-awesome/css/font-awesome.min.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
//Bootbox
import bootbox from 'bootbox';
//Autocomplete
import 'devbridge-autocomplete'
//OpenLayers
import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer.js';
import TileWMS from 'ol/source/TileWMS.js';
import Projection from 'ol/proj/Projection.js';
import GeoJSON from 'ol/format/GeoJSON.js';
import {Vector as VectorSource} from 'ol/source.js';
import {Fill, Stroke, Style} from 'ol/style.js';
//Main Css
import '../css/main.css'
import CircleStyle from "ol/style/Circle";

//const request = require('request');

const wmsLayer = 'pitesti';
const wmsURL = 'https://s1.nanoterra.ro/?';
const baseWMSLayer = 'pitesti_aplicatie';
const bounds = [482213, 368098, 495679, 377809];

const epsg31700 = new Projection({
    code: 'EPSG:31700',
    units: 'm',
    axisOrientation : 'neu'
});

const map = new Map({
    target: 'map',
    layers: [
        new TileLayer({
            extent : bounds,
            source: new TileWMS({
                ratio: 1,
                url: wmsURL,
                params: {
                    'LAYERS': baseWMSLayer,
                    'FORMAT': 'image/jpeg'
                },
                gutter: 0,
                serverType: 'geoserver'
            }),
            title : wmsLayer,
            zoomFactor: 1
        })
    ],
    view: new View({
        projection : epsg31700,
        minZoom : 14,
        maxZoom : 22
    })
});

const view = map.getView();
view.fit(bounds,map.getSize());

map.on('click', function(evt) {
    $.ajax({
        url: "https://api.parcaripitesti.ro/ParkingLot/" + evt.coordinate[1] + "-" + evt.coordinate[0],
        crossDomain: true,
        dataType: "json",
        success: function(data) {
            //on not found stop
            if (typeof data.message !== 'undefined')
                return;
            //setup remote
            let setup = {
                'tip_parcare': {
                    0: "Nespecificat",
                    1: "Resedinta",
                    2: "Cu plata",
                    3: "Supraterana",
                    4: "Mircea Eliade"
                },
                'cartier': {
                    0: "Nespecificat",
                    1: "Banat",
                    2: "Calea Bucuresti (Ceair)",
                    3: "Calea Campulung",
                    4: "Central",
                    5: "Craiovei",
                    6: "Gavana",
                    7: "Marasesti",
                    8: "Negru Voda (Nord)",
                    9: "Prundu",
                    10: "Razboieni",
                    11: "Traian",
                    12: "Trivale",
                    13: "Tudor Vladimirescu"
                }
            };
            //columns have the same value
            setup.tip_administrare = setup.tip_parcare;
            //start table
            let html = '<table class="table table-striped">';
            //loop json columns
            $.each(data, function (key, val) {
                //don't display geometry
                if (key === 'geom') {
                    return;
                }
                //don't display price & phone if null
                if(val === null && (key == 'tarif' || key == 'telefon')){
                    return;
                }
                //taxi
                if(data.numar_locuri_taxi > 0 && (key == 'numar_locuri_totale' || key == 'numar_locuri_libere')) {
                    return;
                }else if(key == 'numar_locuri_taxi' && val == 0){
                    return;
                }
                //fix remote values
                if (setup.hasOwnProperty(key))
                    val = setup[key][val] || "Nespecificat";
                //null
                if (val == null)
                    val = "Nespecificat";
                //create table row & data
                html += '<tr>' +
                    '<th class="text-capitalize">' + (key.split('_').join(' ')) + '</th>' +
                    '<td>' + val + '</td>' +
                    '</tr>'
            });
            //end table
            html += '</table>';
            //highlight geometry
            highlight(data.geom);
            //show table
            bootbox.dialog({
                title: 'Detalii parcaj',
                message: html,
                buttons: {
                    ok: {
                        label: "Inchide",
                        className: 'btn-primary',
                        callback: function () {
                            clearDraw();
                            return true;
                        }
                    }
                },
                onEscape: function(){
                    clearDraw();
                },
                className: 'modal-left',
                backdrop: false
            });
        },
        error: function(xhr, status){
            console.log(status);
        }
    });
});
//zoom to
function zoomTo(x,y, zoom){
    //zoom level
    zoom = zoom||20;
    //view
    let view = map.getView();
    //center
    view.setCenter([parseFloat(x), parseFloat(y)]);
    //zoom
    view.setZoom(zoom);
}
//address search
let searchAfter = "#search_after";
let searchInput = "[name='search']";
let f = $(searchAfter).val();
$(searchAfter).change(function() {
    $(searchInput).val("");
    load_autocomplete($(this).val())
});
load_autocomplete(f);
function load_autocomplete(c) {
    if (c === "strazi") $(searchInput).attr('placeholder', 'Cautare dupa numele strazii...');
    else if (c === "cladiri") $(searchInput).attr('placeholder', 'Cauta o adresa...');
    $(searchInput).autocomplete('clear').autocomplete({
        serviceUrl: 'https://app.nanoterra.ro/harti/ajax/search?oras=pitesti&search_after=' + c,
        noCache: true,
        minChars: 2,
        deferRequestBy: 300,
        dataType: 'jsonp',
        crossDomain: true,
        onSelect: function (a) {
            //view
            zoomTo(a.x, a.y);
            //claer input
            $('[name="search"]').val('');
        },
        onSearchStart: function () {
            window.searchError = null;
            $("#search_loader").show()
        },
        onSearchComplete: function (a, b) {
            $("#search_loader").hide()
        },
        transformResult: function (b) {
            return {
                suggestions: $.map(b.suggestions, function (a) {
                    return {
                        value: a.name,
                        data: a.name,
                        x: a.x,
                        y: a.y
                    }
                })
            }
        }
    });
}

// GeoJSON
function highlight(json){

    clearDraw();

    if(typeof json === 'string'){
        json = JSON.parse(json);
    }


    let geoImage = new CircleStyle({
        radius: 10,
        fill: new Fill({
            color: 'rgba(255, 255, 255, 0.5)'
        }),
        stroke: new Stroke({color: 'red', width: 2})
    });

    let geoStyles = {
        'MultiPolygon': new Style({
            fill:   new Fill  ({
                color: [238, 153, 0, 0.2]
            }),
            stroke: new Stroke({
                color: '#3399CC', width: 2
            })
        }),
        'Polygon': new Style({
            stroke: new Stroke({
                color: '#3399CC',
                width: 2
            }),
            fill: new Fill({
                color: [238, 153, 0, 0.3]
            })
        }),
        'Point': new Style({
            image: geoImage
        })
    };

    let geoStyleFunction = function(feature) {
        feature.setStyle(geoStyles[feature.getGeometry().getType()]);
    };

    let vectorSource = new VectorSource({
        features: (new GeoJSON()).readFeatures(json)
    });

    let vectorLayer = new VectorLayer({
        source: vectorSource,
        style: geoStyleFunction,
        title: "dbLayer"
    });

    vectorLayer.setZIndex(1000);

    map.addLayer(vectorLayer);
}
function clearDraw(){
    //clear geom
    let mLayers = map.getLayers().getArray();
    let index  = mLayers.length -1;
    while( index > 0 ){
        var layerTitle = mLayers[index].getProperties()['title'];
        if(layerTitle === "dbLayer"){
            map.removeLayer(mLayers[index]);
        }
        index--;
    }
}
//qr
$(function() {
    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });
    if (typeof params.qr !== 'undefined' && params.qr) {
        //search for parking space
        $.ajax({
            url: "https://solicitari.parcaripitesti.ro/api/qr/" + params.qr,
            crossDomain: true,
            dataType: "json",
            success: function(geom) {
                if(typeof geom.coordinates !== 'undefined' && geom.coordinates){
                    highlight(geom);
                    zoomTo(geom.coordinates[0], geom.coordinates[1], 21);
                }
            },
            error: function(xhr, status){
                console.log(status);
            },

        }).always(function(){
            console.log(1);
            //clear hash
            window.history.pushState({}, document.title, "/");
        });
    }
});